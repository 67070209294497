<script setup lang="ts">
import { computed, watchEffect } from 'vue';
import { useRoute } from 'vue-router';

import TheSidebarMainMenu from './TheSidebarMainMenu.vue';
import TheSidebarSubMenu from './TheSidebarSubMenu.vue';

const emit = defineEmits<{
  'submenu-expanded': [boolean];
}>();

const route = useRoute();

const isSubmenuExpanded = computed(() => {
  const isFreightRoute = route.meta.product === 'lng-freight-platform';
  const isCargoRoute = route.meta.product === 'lng-basis-platform';
  const isAccessRoute = route.meta.product === 'access-platform';
  const isGasRoute = route.meta.product === 'gas-platform';
  const isSubmenuRoute =
    isFreightRoute || isCargoRoute || isAccessRoute || isGasRoute;

  return isSubmenuRoute;
});

watchEffect(() => {
  emit('submenu-expanded', isSubmenuExpanded.value);
});
</script>

<template>
  <!-- uses relative and z-index here because we want the active page arrow 
       indicator to be above the right main content-->
  <div
    class="relative sticky top-0 z-[970] hidden h-[100svh] grid-cols-[48px_184px] transition-[grid-template-rows_opacity] data-[expanded=false]:grid-cols-[48px_0px] hd:grid"
    :data-expanded="isSubmenuExpanded"
  >
    <div class="relative min-h-0">
      <TheSidebarMainMenu class="absolute z-50" />
    </div>

    <div class="relative min-h-0">
      <div
        class="absolute bottom-0 left-0 top-0 w-[calc(100%-0.5rem)] bg-[#222a38]"
      ></div>
      <TheSidebarSubMenu />
    </div>
  </div>
</template>
