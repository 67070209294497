<script setup lang="ts">
import { computed, inject } from 'vue';
import { useRoute } from 'vue-router';

import { assertIsDefined } from '#/utils/asserts';

import { THE_SIDE_BAR_MAIN_MENU_CONTEXT_KEY } from './share';

const props = defineProps<{
  commodities: Array<{
    to: '/lng' | '/gas';
    key: 'lng' | 'gas';
    title: string;
    showRedDot?: boolean;
  }>;
}>();

const route = useRoute();

const context = inject(THE_SIDE_BAR_MAIN_MENU_CONTEXT_KEY);
assertIsDefined(
  context,
  'TheSidebarMainMenuCommoditySwitch requires TheSidebarMainMenu as parent',
);
const { isExpanded } = context;

const activeCommodity = computed(() => {
  // TODO: use route config
  return route.meta.product === 'gas-platform' ? 'gas' : 'lng';
});

const commoditiesDisplay = computed(() => {
  return props.commodities
    .map((commodity) => {
      return {
        ...commodity,
        visible: isExpanded ? true : commodity.key === activeCommodity.value,
        showRedDot: false,
      };
    })
    .sort((a) => {
      // by whether route is active
      if (a.key === activeCommodity.value) {
        return -1;
      }
      return 0;
    });
});
</script>

<template>
  <div
    class="col-span-2 grid h-11 text-gray-100"
    :class="{
      'grid-cols-[subgrid]': !isExpanded,
      'grid-cols-2': isExpanded,
    }"
  >
    <RouterLink
      v-for="commodity in commoditiesDisplay"
      :key="commodity.title"
      :to="commodity.to"
      class="ease-out-in overflow-hidden text-gray-400 transition-colors transition-opacity duration-300"
      :class="{
        hidden: !commodity.visible,
        'hover:bg-gray-700/50 hover:text-gray-100':
          activeCommodity !== commodity.key,
        'opacity-0': activeCommodity !== commodity.key && !isExpanded,
        'bg-green-700 text-white hover:text-white':
          commodity.key === 'lng' && activeCommodity === 'lng',
        'bg-blue-600 text-white hover:text-white':
          commodity.key === 'gas' && activeCommodity === 'gas',
      }"
    >
      <div class="relative flex h-full items-center justify-center">
        <span class="font-display font-bold">{{ commodity.title }}</span>
        <sup
          v-if="commodity.showRedDot"
          class="absolute right-2 top-1 block h-[6px] w-[6px] rounded-full bg-red"
        ></sup>
      </div>
    </RouterLink>
  </div>
</template>
