import type { RouteRecordRaw } from 'vue-router';

import RouteView from '@/shared/components/RouteView.vue';
import AppLayout from '@/shared/layout/AppLayout.vue';
import { features } from '@/shared/misc/constants';

const THEDiscoverPage = () =>
  import('@/products/gas/pages/discover/THEDiscoverPage.vue');

const TTFDiscoverPage = () =>
  import('@/products/gas/pages/discover/TTFDiscoverPage.vue');

const routes: RouteRecordRaw[] = [
  {
    path: '/gas',
    component: RouteView,
    redirect: '/gas/leba-the',
    children: [
      {
        path: 'leba-the',
        name: features.GAS_LEBA_THE,
        component: THEDiscoverPage,
        meta: {
          title: 'LEBA THE',
          product: 'gas-platform',
          requiresAuth: true,
          basic: false,
          layout: AppLayout,
          featureIdTemplate: `${features.FREIGHT_PHYSICAL_PREFIX}THE`,
        },
      },
      {
        path: 'leba-ttf',
        name: features.GAS_LEBA_TTF,
        component: TTFDiscoverPage,
        meta: {
          title: 'LEBA TTF',
          product: 'gas-platform',
          requiresAuth: true,
          basic: false,
          layout: AppLayout,
          featureIdTemplate: `${features.FREIGHT_PHYSICAL_PREFIX}TTF`,
        },
      },
    ],
  },
];

export default routes;
